import Image from '@atoms/Image';
import LinkButton from '@atoms/LinkButton';
import type { ReactElement } from 'react';
import { Fragment } from 'react';

import {
  ButtonWrapper,
  Icon,
  ImagesWrapper,
  Overlay,
  SingleImageWrapper,
  Wrapper,
} from './InstagramPreview.styled';
import type { InstagramPreviewProps } from './InstagramPreview.types';

export const InstagramPreview = ({
  instagramPosts,
  title,
  profileLink,
}: InstagramPreviewProps): ReactElement => (
  <Wrapper>
    <h2>📱 {title}</h2>
    <ImagesWrapper>
      {instagramPosts?.map((image, index) => (
        <Fragment key={`instagramImage-${index}`}>
          <a href={image.link} target="_blank" rel="noopener noreferrer">
            <SingleImageWrapper>
              <Image
                alt={image.alt}
                src={image.src}
                width={100}
                height={100}
                quality={40}
              />
              <Overlay className="overlay" />
              <Icon className="icon" />
            </SingleImageWrapper>
          </a>
        </Fragment>
      ))}
    </ImagesWrapper>
    <ButtonWrapper>
      <LinkButton link={profileLink}>🤳 Zobacz profil</LinkButton>
    </ButtonWrapper>
  </Wrapper>
);
