import { domains } from '@config/domains';
import type { ISanityCategory } from '@sanity/types/category';
import type { ISanityPost } from '@sanity/types/post';

export const convertToExcerpt = (text: string): string => {
  const words = text.split(' ');
  if (words.length > 30) {
    const excerpt = words.splice(0, 30).join(' ');
    return `${excerpt}...`;
  }
  return text;
};

export const formatDate = (date: string): string => {
  const rawDate = new Date(date);
  const months = [
    'stycznia',
    'lutego',
    'marca',
    'kwietnia',
    'maja',
    'czerwca',
    'lipca',
    'sierpnia',
    'września',
    'października',
    'listopada',
    'grudnia',
  ];

  const format = (date: Date): string => {
    const splitDate = date.toLocaleDateString('pl-PL').split('.');
    const monthNumber = parseInt(splitDate[1]?.replace(/^0/, ''), 10);
    return `${splitDate[0]} ${months[monthNumber - 1]} ${splitDate[2]}`;
  };

  return format(rawDate);
};

export const shortenText = (text: string): string => {
  if (typeof text !== 'string' || text.length <= 20) return text;

  return `${text.slice(0, 20)}...`;
};

export const getTagNameBySlug = (
  slug: string,
  allTags: ISanityCategory[],
): string => {
  const currentTag = allTags.filter(tag => tag.slug === slug);
  return currentTag[0].title;
};

export const removeDigitAndDot = (text: string): string =>
  text.replace(/^\d+\./, '').trim();

export const changeStringToId = (text: string): string => {
  if (!text) return '';

  return text
    .replace(/\s/g, '-')
    .replace(/[^a-zA-Z0-9-]/g, '')
    .toLowerCase();
};

export const getFeaturedPosts = (posts: ISanityPost[]): ISanityPost[] =>
  posts.filter(post => post.featured);

export const sanitizeString = (text: string): string =>
  text.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();

export const preventOrphans = (text: string): string => {
  if (!text) return;

  text.replace(/(\s)(\w{1,2})($|\s)/g, '$1$2&nbsp;');
};

export const isLocalDomain = (url: string): boolean =>
  domains.some(domain => url.includes(domain)) || url.startsWith('/');
