import Link from 'next/link';
import type { ReactElement } from 'react';

import { isLocalDomain } from '@/utils';

import { StyledLink, StyledLinkWrapper } from './LinkButton.styled';
import type { LinkButtonProps } from './LinkButton.types';

export const LinkButton = ({
  children,
  link,
}: LinkButtonProps): ReactElement => {
  if (isLocalDomain(link)) {
    return (
      <StyledLinkWrapper>
        <Link href={link}>{children}</Link>
      </StyledLinkWrapper>
    );
  }

  return (
    <StyledLink href={link} rel="noopener noreferrer" target="_blank">
      {children}
    </StyledLink>
  );
};
